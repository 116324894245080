/* Heros.css */
.hero-section {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .hero-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .hero-content {
    position: absolute;
    top: 40%;
    left: 30%; /* Adjust the left position to 10% or any other value as per your preference */
    transform: translate(-50%, -50%);
    text-align: left;
    color: #fff;
    max-width: 50%;
    /* padding: 20px; */
  }
  
  .company-name {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .slogan {
    font-size: 1.5rem;
  }
  
  .additional-text {
    font-size: 1rem; /* Adjust the font size as needed */
    margin-top: 10px; /* Add margin to separate it from the slogan */
  }
  


/* Heros.css */
.signature-animation {
    display: inline-block;
    opacity: 0;
    animation: fadeIn 2s infinite;
  }
  
  @keyframes fadeIn {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  .delay-1 {
    animation-delay: 0.1s;
  }
  
  .delay-2 {
    animation-delay: 0.2s;
  }
  
  /* Add more delay classes as needed */
  /* Heros.css */

.hero-section {
  position: relative;
  text-align: center;
  color: white;
}

.hero-video {
  width: 100%;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.company-name {
  font-size: 2.5rem;
}

h3 {
  font-size: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
}

.signature-animation {
  display: inline-block;
  font-size: 1.5rem;
}

.slogan {
  font-size: 1.2rem;
}

/* Add media queries for responsiveness */
@media only screen and (max-width: 768px) {
  .company-name {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  .signature-animation {
    font-size: 1.2rem;
  }

  .slogan {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .company-name {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  .signature-animation {
    font-size: 1rem;
  }

  .slogan {
    font-size: 0.8rem;
  }
}

  