.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  .carousel-slide {
    min-width: 100%;
    flex-shrink: 0;
  }
  
  .carousel-prev-btn,
  .carousel-next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .carousel-prev-btn {
    left: 0;
  }
  
  .carousel-next-btn {
    right: 0;
  }
  