/* PrestigiousComponent.css */

.prestigious-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .row {
    display: flex;
    margin-bottom: 20px;
  }
  
  .image-card {
    width: 200px; /* Adjust the width as needed */
    height: 150px; /* Adjust the height as needed */
    margin: 0 10px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .image-card:hover {
    transform: scale(1.1);
  }
  
  .image-card img {
    width: 180px;
    height: 110px;
    object-fit: cover;
    border-radius: 8px;
  }

  .image-card:hover {
    transform: scale(1.1);
  }

  /* responsive-css */
/* PrestigiousComponent.css */

.prestigious-container {
  text-align: center;
}

h2 {
  font-size: 2rem;
}

.image-card {
  display: inline-block;
  margin: 10px;
}

.imagetaht {
  max-width: 100%;
  height: auto;
}

/* Add media queries for responsiveness */
@media only screen and (max-width: 768px) {
  h2 {
    font-size: 1.5rem;
  }

  .image-card {
    margin: 5px;
  }
}

@media only screen and (max-width: 480px) {
  h2 {
    font-size: 1.2rem;
  }

  .image-card {
    margin: 3px;
  }
}

/* PrestigiousComponent.css */

.prestigious-container {
  text-align: center;
}

h2 {
  font-size: 2rem;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.image-card {
  margin: 10px;
}

.imagetaht {
  max-width: 100%;
  height: auto;
}

/* Add media queries for responsiveness */
@media only screen and (max-width: 768px) {
  h2 {
    font-size: 1.5rem;
  }

  .image-card {
    margin: 5px;
  }
}

@media only screen and (max-width: 480px) {
  h2 {
    font-size: 1.2rem;
  }

  .image-card {
    margin: 3px;
  }
}


 
  